






































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
// import Player from 'xgplayer'
import Table from '@/components/GateTable/index.vue'
// import flvjs from 'flv.js'
import { getDicts, getDictName } from '@/utils/dict'
import { Dialog, Popover } from 'element-ui'
Vue.use(Dialog)
Vue.use(Popover)
@Component({
  components: {
    Table
  }
})
export default class GateGard extends Vue {
  $constants: any
  isShowMore = false
  isShowDialog = false
  isNeedId = false
  nowTime = ''
  // 用户信息
  user = [] as any
  // 所有设备组
  allEquipGroup: any = []
  // 门禁权限
  userEquipGroupLimit: any = []
  deviceLists: any = []
  // 默认人脸女icon
  defaultFemaleIcon = 'this.src="' + require('@/assets/images/female.png') + '"'
  // 男icon
  defaultmaleIcon = 'this.src="' + require('@/assets/images/male.png') + '"'
  // 人脸门禁表头
  faceTableHeader = [
    {
      key: 'faceImg',
      name: '照片',
      width: '20%',
      isSpecial: true
    },
    {
      key: 'snapTime',
      name: '时间',
      width: '30%'
    },
    {
      key: 'houseNo',
      name: '所属房屋',
      width: '25%',
      isSpecial: true
    },
    {
      key: 'openType',
      name: '进出方式',
      width: '25%',
      isSpecial: true
    }
  ]
  // 人脸门禁数据
  faceRecordList: any = []
  // 人员进出类型
  openTypeDicts: any = []
  // 车闸表头
  carTableHeader = [
    {
      key: 'carNo',
      name: '车牌',
      width: '20%',
      isSpecial: true
    },
    {
      key: 'time',
      name: '时间',
      width: '30%',
      isSpecial: true
    },
    {
      key: 'houseNo',
      name: '所属房屋',
      width: '25%',
      isSpecial: true
    },
    {
      key: 'direction',
      name: '进出类型',
      width: '25%',
      isSpecial: true
    }
  ]
  // 车闸数据
  carLogList: any = []
  // 车闸进出字典
  carDirectionDicts = [] as any
  // 车闸弹窗队列
  carShowLists = [] as any
  // socket配置
  websock: any = null
  reconnectData: any = null
  serverTimeoutObj: any = null
  lockReconnect = false //避免重复连接，因为onerror之后会立即触发 onclose
  timeout = 30000 //30s一次心跳检测
  timeoutObj: any = null
  // websocketTestUrl = 'ws://community-dev.jiketravel.com/service/c-pc/ws/index'
  // 开发环境
  websocketTestUrl = 'wss://community-test.jiketravel.com/api/c-pc/ws/index'
  // 李本地
  // websocketTestUrl = 'ws://10.0.10.109:8021/service/c-pc/ws/index'
  // 生产环境
  websocketProductUrl = 'wss://community.luopan88.com/api/c-pc/ws/index'

  // // 视频一
  // flvPlayerFirst: any = null
  // // 视频二
  // flvPlayerSecond: any = null

  // websockst 直播
  checkWsOnline: any = null
  wsOffline: any = false
  manualClose: any = false
  ws: any = null
  oldWsUrl: any = ''
  videoFirstUrl: any = ''
  videoSecondUrl: any = ''
  defaultVideoImg = 'this.src="' + require('@/assets/images/novideo.png') + '"'

  async beforeCreate() {
    // 获取相关字典
    // 设备类型
    this.carDirectionDicts = await getDicts('CAR_BRAKE_DIRECTION')
    // 人员进出类型
    this.openTypeDicts = await getDicts('FACE_LOG_OPEN_TYPE')
  }
  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, '' + val)
  }
  mounted() {
    // 监听页面是否切换
    // document.addEventListener('visibilitychange', this.handleVisiable)
    const user = sessionStorage.getItem('user')
    if (!user) {
      this.$router.push('/')
      sessionStorage.clear()
      localStorage.clear()
    } else {
      this.user = user && JSON.parse(user)
      // 隐藏退出登录框
      document.addEventListener('click', (e: any) => {
        if (this.$refs.showMore) {
          const isSelf = (this.$refs.showMore as any).contains(e.target)
          if (!isSelf) {
            this.isShowMore = false
          }
        }
      })
      // 获取用户的设备权限，用于获取对应车闸数据
      this.userEquipGroupLimit = user && JSON.parse(user).deviceGroupIds
      this.carShowLists = []
      if (this.userEquipGroupLimit.length > 0) {
        this.getDevicelist()
        this.initWebSocket()
        this.getCarLogList('new')
        this.getFaceRecordList()
      }
      // 获取时间
      this.getTime()
      setInterval(() => {
        this.getTime()
      }, 1000)
      setInterval(() => {
        console.log('刷新')
        window.location.reload()
      }, 1000 * 5 * 60)
    }
  }

  playVideo(url: any, id: any) {
    this.wsOffline = false
    this.manualClose = false
    const _this = this
    this.checkWsOnline = setInterval(function() {
      console.log('setInterval', _this.manualClose, _this.wsOffline)
      if (_this.manualClose) {
        clearInterval(_this.checkWsOnline)
      } else {
        if (_this.wsOffline) {
          console.log('playVideo', url, id)
          clearInterval(_this.checkWsOnline)
          _this.playVideo(url, id)
        }
      }
    }, 5000)
    const imgOne: any = document.getElementById(id)
    //链接服务端
    this.ws = new WebSocket(url)
    //配置客户端连接成功的回调方法
    this.ws.onopen = function() {
      console.log('wsVideo连接成功', url)
      this.wsOffline = false
    }
    const img: any = new Image()
    img.onload = function() {
      imgOne.src = img.src
    }
    this.ws.onmessage = function(event: any) {
      this.wsOffline = false
      const reader = new FileReader()
      reader.readAsDataURL(event.data)
      reader.onload = function() {
        img.src = this.result
      }
    }
    this.ws.onerror = function() {
      console.log('error...')
      this.wsOffline = true
    }
    this.ws.onclose = function() {
      console.log('close...')
      this.wsOffline = true
    }
  }

  // handleVisiable(e: any) {
  //   // 监听到网页tab切换
  //   if (e.target.visibilityState === 'visible') {
  //     console.log('visible=====')
  //     // location.reload()
  //   }
  // }
  // beforeDestroy() {
  //   this.flvPlayerFirst.pause()
  //   this.flvPlayerFirst.unload()
  //   this.flvPlayerFirst.detachMediaElement()
  //   this.flvPlayerFirst.destroy()
  //   this.flvPlayerFirst = null
  //   this.flvPlayerSecond.pause()
  //   this.flvPlayerSecond.unload()
  //   this.flvPlayerSecond.detachMediaElement()
  //   this.flvPlayerSecond.destroy()
  //   this.flvPlayerSecond = null
  // }

  destroyed() {
    // document.removeEventListener('visibilitychange', this.handleVisiable)
    //离开路由之后断开websocket连接
    if (this.user && this.user.username) {
      this.ws.close()
      if (this.userEquipGroupLimit && this.userEquipGroupLimit.length > 0) {
        this.lockReconnect = true
        this.websock.close() //离开路由之后断开websocket连接
        clearTimeout(this.reconnectData) //离开清除 reconnectData
        clearTimeout(this.timeoutObj) //离开清除 timeoutObj
        clearTimeout(this.serverTimeoutObj) //离开清除 serverTimeoutObj
      }
    }
  }

  getDevicelist() {
    this.$api.gate
      .getEquipmentList({
        groupId: this.userEquipGroupLimit[0],
        type: 2,
        size: '-1'
      })
      .then((res: any) => {
        if (res.data.success) {
          this.deviceLists = res.data.data
          this.videoFirstUrl = `wss://192.168.10.200:8016/ws?ip=${this.deviceLists[0].ip}`
          this.videoSecondUrl = `wss://192.168.10.200:8016/ws?ip=${this.deviceLists[1].ip}`
          this.playVideo(this.videoFirstUrl, 'videoFirst')
          this.playVideo(this.videoSecondUrl, 'videoSecond')
          // if (flvjs.isSupported() && this.deviceLists.length > 0) {
          //   if (this.deviceLists[0].streamUrl) {
          //     console.log('flv1 start', this.deviceLists[0].streamUrl)
          //     const videoFirst: HTMLMediaElement = document.getElementById(
          //       'videoElementFirst'
          //     ) as HTMLMediaElement
          //     console.log('videoFirst', videoFirst)
          //     this.flvPlayerFirst = flvjs.createPlayer(
          //       {
          //         type: 'flv',
          //         isLive: true,
          //         url: this.deviceLists[0].streamUrl
          //       },
          //       {
          //         enableStashBuffer: false,
          //         fixAudioTimestampGap: false,
          //         isLive: true
          //       }
          //     )
          //     if (videoFirst) {
          //       this.flvPlayerFirst.attachMediaElement(videoFirst)
          //       this.flvPlayerFirst.load()
          //       this.flvPlayerFirst.play()
          //       this.flvPlayerFirst.on('error', (err: any) => {
          //         console.log('flvPlayerFirst error', err)
          //       })
          //     }
          //   }
          //   if (this.deviceLists.length > 1 && this.deviceLists[1].streamUrl) {
          //     console.log('flv2 start', this.deviceLists[1].streamUrl)
          //     // videojs.options.flash.swf = 'https://cdn.bootcss.com/videojs-swf/5.4.1/video-js.swf';
          //     const videoSecond: HTMLMediaElement = document.getElementById(
          //       'videoElementSecond'
          //     ) as HTMLMediaElement
          //     console.log('videoSecond', videoSecond)
          //     this.flvPlayerSecond = flvjs.createPlayer(
          //       {
          //         type: 'flv',
          //         isLive: true,
          //         url: this.deviceLists[1].streamUrl
          //       },
          //       {
          //         enableStashBuffer: false,
          //         fixAudioTimestampGap: false,
          //         isLive: true
          //       }
          //     )
          //     if (videoSecond) {
          //       this.flvPlayerSecond.attachMediaElement(videoSecond)
          //       this.flvPlayerSecond.load()
          //       this.flvPlayerSecond.play()
          //       this.flvPlayerSecond.on('error', (err: any) => {
          //         console.log('flvPlayerSecond error', err)
          //       })
          //     }
          //   }
          // }
        }
      })
  }
  go2Home() {
    // 跳转首页
    this.$router.push('/homepage')
  }
  getTime() {
    this.nowTime = moment().format('yyyy-MM-DD HH:mm:ss')
  }

  loginOut() {
    this.$api.login.logout().then((res: any) => {
      if (res.data.success) {
        this.$router.push('/')
        this.$message.success(res.data.msg)
        sessionStorage.clear()
        localStorage.clear()
      }
    })
  }

  // 获取人脸识别记录
  getFaceRecordList() {
    if (this.userEquipGroupLimit.length > 0) {
      this.$api.home
        .getFaceRecordList({
          size: 4,
          current: 1,
          devGroupIds: this.userEquipGroupLimit.toString()
        })
        .then((res: any) => {
          if (res.data.success) {
            this.faceRecordList = res.data.data
          }
        })
    }
  }
  // 获取车闸记录
  getCarLogList(type?: any) {
    if (this.userEquipGroupLimit.length > 0) {
      this.$api.home
        .getCarRecordList({
          size: 4,
          current: 1,
          devGroupIds: this.userEquipGroupLimit.toString()
        })
        .then((res: any) => {
          if (res.data.success) {
            this.carLogList = res.data.data
            // if (type && type !== 'socket') {
            //   this.carLogList.forEach((item: any) => {
            //     // 筛选出列表carLogList里status 0(没有处理的车辆)，
            //     // 车辆 不弹出 resType 不为0（有效车），direction不为2（出去）的车辆
            //     // resType 0：有效车，1：车辆到期，2：车位已满，3：没有权限，-1：没处理的临时车
            //     if (
            //       item.status === 0 &&
            //       item.resType !== 0 &&
            //       item.direction !== 2
            //     ) {
            //       item.snapTime = moment(item.time).format(
            //         'yyyy-MM-DD HH:mm:ss'
            //       )
            //       if (item.resType === 1) {
            //         item.remark = '车辆已到期！'
            //       } else if (item.resType === 2) {
            //         item.remark = '车位已满！'
            //       }
            //       this.carShowLists.push(item)
            //     }
            //   })
            //   if (this.carShowLists.length > 0) {
            //     this.isShowDialog = true
            //   }
            // }
          }
        })
    }
  }

  // 车闸放行
  submitCar(car: any) {
    // console.log('放行car', car)
    const p1 = {
      dataId: car.id,
      remark: car.remark,
      status: '1',
      userId: this.user.id
    }
    this.$api.home.checkCar(p1).then((res: any) => {
      if (res.data.success) {
        this.carShowLists.shift()
        this.isShowDialog = false
        const t = setInterval(() => {
          if (this.carShowLists.length > 0) {
            this.isShowDialog = true
          }
          clearInterval(t)
        }, 1000)
      } else if (res.data.code === 'S1003') {
        this.carShowLists.shift()
        this.isShowDialog = false
        const t = setInterval(() => {
          if (this.carShowLists.length > 0) {
            this.isShowDialog = true
          }
          clearInterval(t)
        }, 1000)
      }
    })
  }

  // 车闸拒绝
  refuseCar(car: any) {
    // console.log('拒绝car', car)
    const p2 = {
      dataId: car.id,
      remark: car.remark,
      status: '2',
      userId: this.user.id
    }
    this.$api.home.checkCar(p2).then((res: any) => {
      if (res.data.success) {
        this.carShowLists.shift()
        this.isShowDialog = false
        const t = setInterval(() => {
          if (this.carShowLists.length > 0) {
            this.isShowDialog = true
          }
          clearInterval(t)
        }, 1000)
      }
    })
  }

  //初始化weosocket
  initWebSocket() {
    // 获取当前页面地址
    const wPath = window.document.location.href
    // console.log(
    //   'wPath',
    //   wPath,
    //   wPath.includes('localhost'),
    //   window.document.location.host
    // )
    // console.log('启动中')
    if (wPath.includes('localhost')) {
      this.websock = new WebSocket(this.websocketTestUrl)
      // console.log('socket连接地址', this.websocketTestUrl)
    } else {
      this.websocketProductUrl =
        'wss://' + window.document.location.host + '/api/c-pc/ws/index'
      this.websock = new WebSocket(this.websocketProductUrl)
      // console.log('socket连接地址', this.websocketProductUrl)
    }
    this.websock.onopen = this.websocketonopen //连接成功
    this.websock.onmessage = this.websocketonmessage //广播成功
    this.websock.onerror = this.websocketonerror //连接断开，失败
    this.websock.onclose = this.websocketclose //连接关闭
  }

  websocketonopen() {
    // console.log('wsDevice连接成功')
    this.websocketsend()
    this.heatBeat()
  }

  websocketsend() {
    if (this.userEquipGroupLimit.length > 0) {
      const user = sessionStorage.getItem('user')
      const userId = user && JSON.parse(user).id
      const token = sessionStorage.getItem('token')
      const message = {
        msgType: 'Index_Subscribe_Devices',
        msg: {
          userId: userId,
          deviceGroupIds: this.userEquipGroupLimit
        },
        token: token
      }
      // 向后端发送数据
      // console.log('wsDevice向后端发送数据', message)
      this.websock.send(JSON.stringify(message))
    }
  }

  websocketonerror(e: any) {
    // console.log('wsDevice连接发生错误', e)
    // 重新连接
    this.reconnect()
  }
  websocketonmessage(e: any) {
    this.heatBeat()
    const data = JSON.parse(e.data)
    // console.log('wsDevice推送的数据====', data)
    // socket 返回code为'Index_Car_Travel'车闸推送，'Index_Face_Log'为人脸推送
    if (data.code === 'Index_Car_Travel') {
      // 车闸有推送，更新车闸流量记录
      this.getCarLogList('socket')
      // 车辆 不弹出 resType 不为0（有效车），direction不为2（出去）的车辆
      // resType 0：有效车，1：车辆到期，2：车位已满，3：没有权限，-1：没处理的临时车
      // direction 1：进， 2：出
      if (data.data.resType !== 0 && data.data.direction !== 2) {
        data.data.snapTime = moment(data.data.time).format(
          'yyyy-MM-DD HH:mm:ss'
        )
        if (data.data.resType === 1) {
          data.data.remark = '车辆已到期！'
        } else if (data.data.resType === 2) {
          data.data.remark = '车位已满！'
        }
        this.carShowLists.push(data.data)
        if (!this.isShowDialog) {
          this.isShowDialog = true
        }
      }
    } else if (data.code === 'Index_Face_Log') {
      this.getFaceRecordList()
    }
  }
  websocketclose(e: any) {
    // console.log('wsDevice断开连接', e)
    // 重新连接
    this.reconnect()
  }
  reconnect() {
    // console.log('wsDevice重新连接')
    if (this.lockReconnect) {
      return
    }
    this.lockReconnect = true
    this.reconnectData && clearTimeout(this.reconnectData)
    this.reconnectData = setTimeout(() => {
      this.initWebSocket()
      this.lockReconnect = false
    }, 4000)
  }
  heatBeat() {
    this.timeoutObj && clearTimeout(this.timeoutObj)
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
    this.timeoutObj = setTimeout(() => {
      //这里发送一个心跳，后端收到后，返回一个心跳消息
      const user = sessionStorage.getItem('user')
      const userId = user && JSON.parse(user).id
      const token = sessionStorage.getItem('token')
      const message = {
        msgType: 'Index_Subscribe_Devices',
        msg: {
          userId: userId,
          deviceGroupIds: this.userEquipGroupLimit
        },
        token: token
      }
      // console.log('wsDevice心跳检测，向后端发送数据', message)
      this.websock.send(JSON.stringify(message))
      this.serverTimeoutObj = setTimeout(() => {
        this.websock.close() //如果  5秒之后我们没有收到 后台返回的心跳检测数据 断开socket，断开后会启动重连机制
      }, 5000)
    }, this.timeout)
  }
}
