



































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator'
import { Checkbox, Popover } from 'element-ui'
Vue.use(Checkbox)
Vue.use(Popover)

// 门岗的表格
@Component
export default class GateTable extends Vue {
  // 表格行高度
  @Prop({ default: 54 }) private height!: number
  // 表格内容区域100vh需要减去的高度
  @Prop({ default: 366 }) private contHeight!: number
  @Prop({ default: true }) private isNeedcontHeight!: boolean
  @Prop({ default: false }) private loading!: boolean
  @Prop() private header!: object[]
  @Prop() private content!: object[]
}
